/* source-sans-3-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/source-sans-3-v9-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 200;
  src: url('fonts/source-sans-3-v9-latin-200italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/source-sans-3-v9-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/source-sans-3-v9-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/source-sans-3-v9-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/source-sans-3-v9-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/source-sans-3-v9-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/source-sans-3-v9-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/source-sans-3-v9-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/source-sans-3-v9-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/source-sans-3-v9-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/source-sans-3-v9-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/source-sans-3-v9-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/source-sans-3-v9-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/source-sans-3-v9-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/source-sans-3-v9-latin-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


.backgroundcolorcontainer{
	background-color: var(--euro-blue-active); 
	background: linear-gradient(135deg,rgb(85,173,220) 37%,rgb(4,118,169) 70%);
}
.ekform-container {
	box-sizing: border-box;
	margin: 40px auto 0 auto;
	padding: 0;
	font-family: "Source Sans 3";
	
	
	font-size: 1em;
	font-weight: 300;
	line-height: 1em;	
	
	
	--euro-error:#ff0000;
		
	--euro-bg-input-inactive: #fafafa;
	--euro-bg-input-active: #fafafa;
	
	--euro-textcolor: #333;
	--euro-placeholdercolor: #999;
	
	--euro-grey: #ccc;
	
	--euro-blue-inactive: #7fb9d2;
	--euro-blue-active: #0073a6;
}

h2{
	color:var(--euro-textcolor);

	font-size: 1.8em;
	line-height:1.1em;
  	font-weight: 700;
}
p{
	color:var(--euro-textcolor);
	font-size: 1.4em;
	line-height: 1.3em;
	font-weight: 300;
}

a{
	color:var(--euro-textcolor);
	
}
a:hover{
	color:var(--euro-blue-active);
	
}
.ekform-container * {
	box-sizing: border-box;
}

.ekform-container label {
	
	cursor: pointer;
	
	color: var(--euro-textcolor);
	
	font-size: 1em;
	font-weight: 300;
	line-height: 1.4em;	
}

.sepalabel{
	font-size: 1.2em!important;
	font-weight: 300!important;
	line-height: 1.4em!important;
}

.ekform-container .ekform-block:not(:first-child) {
	margin-top: 16px;
}

fieldset{
	border:0;
}

#zweitermieterja, #zweitermieternein{
	background-color: #fff;
	border-radius: 15px;
	padding: 5px 0px;
	min-width: 50px;
	text-decoration: none;
	color:var(--euro-textcolor);
}
#zweitermieterja a, #zweitermieternein a{
}

.section{
	border: 0px solid #eee;
	background-color: #fff;
	padding:10px;
	margin-top: 10px;
	margin-bottom: 10px;	
	border-radius: 15px;
}
.slidedown{
  overflow: hidden;
  transition: all .3s ease!important;
  -webkit-transition: all .3s ease!important;
  
}
@keyframes slideUp {
  0%,
  50% {
    transform: translateY(100%);
    opacity: 0;
  }
   
  60%,
  100% {
    transform: translateY(0);
    opacity: 1;
  
  }
}
.ergebnis{
	background-color: var(--euro-blue-active); 
	background: linear-gradient(135deg,rgb(85,173,220) 37%,rgb(4,118,169) 70%);
	margin-left: 10px;
	margin-right: 10px;		
}
#ergebnis1{
	color: #fff;
	text-align: center;
}
#ergebnis2{
	color: #fff;
	text-align: center;
}
.ekform-row{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.wrongTextInputField{
	font-size:0.9em;
	line-height:0.8em;
	color: var(--euro-error);
	font-weight: 700;
}

.wrongTextInput{
	border-color: var(--euro-error)!important;
	border-width: 2px!important;
}

.ekform-block-12,
.ekform-block-11,
.ekform-block-10,
.ekform-block-9,
.ekform-block-8,
.ekform-block-7,
.ekform-block-6,
.ekform-block-5,
.ekform-block-4,
.ekform-block-3,
.ekform-block-2,
.ekform-block-1 {
	-webkit-box-flex: 0;
	flex: 0 0 100%;
    max-width: 100%;
	
	display: inline-block;
	margin:  0  !important;
	padding: 5px!important;
	border:  0  !important;
}

.buttonrow{
	padding:10px;
	margin-left: 0px!important;
	margin-right:0px!important;
}

/*=============================================
=            Fields           =
=============================================*/

.ekform-container input,
.ekform-container textarea,
.ekform-container select,
.ekform-container date,
.ekform-container tel {
	margin-top: 4px;
	width: 100%;
	height: 42px;
	border-top: 1px solid var(--euro-grey);
	border-right: 1px solid var(--euro-grey);
	border-bottom: 1px solid var(--euro-grey);
	border-left: 1px solid var(--euro-grey);
	color: var(--euro-blue-active);
	border-radius: 0px;
	padding: 8px 12px;
	
	background-color: var(--euro-bg-input-inactive);

	font-size:14px;	
	font-family: "Source Sans 3";
	font-weight: 700;
}

.ekform-container input[type=checkbox]{
	display: inline-block;
	width:auto;
	height:auto;
}

.ekform-container input[type=radio]{
	display: inline-block;
	width: auto;
	height: auto;
}

.ekform-container textarea{
	min-height: 188px;
	max-width: 100%;
	padding-top: 12px;
}


*:disabled{
	color: #ddd !important;
	background-color: #f0f0f0 !important;
	border: 1px solid #f0f0f0 !important;
}
.ekform-container input::placeholder,
.ekform-container textarea::placeholder,
.ekform-container select::placeholder {
	color: var(--euro-placeholdercolor);
	font-weight: 600;
}



.ekform-container input:hover,
.ekform-container textarea:hover,
.ekform-container select:hover {
	border-color: var(--euro-blue-active);
	background-color: var(--euro-bg-input-inactive);	
}

.ekform-container input:hover::placeholder,
.ekform-container textarea:hover::placeholder,
.ekform-container select:hover::placeholder {
	color: var(--euro-placeholdercolor);
	background-color: var(--euro-bg-input-active);

	font-weight: 600;
}

.ekform-container input:focus,
.ekform-container textarea:focus,
.ekform-container select:focus {
	background-color: var(--euro-bg-input-active);
	border-color: hsl(var(--euro-blue-active));
	box-shadow: hsla(var(--euro-blue-active), 8%) 0px 0px 0px 3px;
	outline: none;
}

.ekform-container input:focus:required:invalid,
.ekform-container input:focus:invalid, 
.ekform-container select:focus:required:invalid,
.ekform-container select:focus:invalid {
	color: hsl(var(--euro-error)); 
	border-color: hsl(var(--euro-error));
	box-shadow: 0px 0px 0px 3px hsla(var(--euro-error), 12%);
}

.ekform-container select {
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9997 14.5001L8.46387 10.9642L9.64303 9.78589L11.9997 12.1434L14.3564 9.78589L15.5355 10.9642L11.9997 14.5001Z' fill='%236C6F93'/%3E%3C/svg%3E%0A");
	/* background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px); */
	background-size: 24px 24px;
	background-position: 98%;
	background-repeat: no-repeat;
	appearance: none;
	-webkit-appearance: none;
	border-color: var(--euro-grey);
	background-color: var(--euro-bg-input-inactive);
}







.ekform-container button {
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -0.02em;
	height: 42px;
	line-height: 40px;
	width: 100%;
	border-radius: 6px;
	box-sizing: border-box;
	border: 0px solid var(--euro-blue-active);
	background-color: var(--euro-blue-inactive);
	color: #fff;
	cursor: pointer;  
	margin-bottom: 25px;
}

.ekform-container button:hover {
	background: var(--euro-blue-active);
}
.ekform-container button:focus {
	background: var(--euro-blue-active);
}
.ekform-container button:active {
	background: var(--euro-blue-active);
}
.ekform-container button:disabled {
	background: var(--euro-grey);
}


.ekform-container button.backbutton {
	
	background-color: var(--euro-grey);
	color: #fff;
	cursor: pointer;
}







@media (min-width: 900px) {
	
	.ekform-container {
	}

	.ekform-block-12{
		flex: 0 0 100%;
		max-width: 100%;
	}	
	.ekform-block-11{
		flex: 0 0 91.66%;
		max-width: 91.66%;
	}	
	.ekform-block-10{
		flex: 0 0 83.33%;
		max-width: 83.33%;
	}	
	.ekform-block-9{
		flex: 0 0 75%;
		max-width: 75%;
	}	
	.ekform-block-8{
		flex: 0 0 66.66%;
		max-width: 66.66%;
	}	
	.ekform-block-7{
		flex: 0 0 58.33%;
		max-width: 58.33%;
	}	
	.ekform-block-6{
		flex: 0 0 50%;
		max-width: 50%;
	}	
	.ekform-block-5{
		flex: 0 0 41.66%;
		max-width: 41.66%;
	}
	.ekform-block-4{
		flex: 0 0 33.33%;
		max-width: 33.33%;
	}
	.ekform-block-3{
		flex: 0 0 25%;
		max-width: 25%;
	}
	.ekform-block-2{
		flex: 0 0 16.66%;
		max-width: 16.66%;
	}
	.ekform-block-1{
		flex: 0 0 8.33%;
		max-width: 8.33%;
	}

}





.progressbar {
    margin: 0 auto;
    padding: 10px;	
    list-style: none;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.progressbar a{
	text-decoration: none;
	text-transform: uppercase;
	
	color:var(--euro-textcolor);
	font-size: 1.4em;
	font-weight: 300;
	
	
}
.is-current a{
	color: #0073a6;	
	font-weight: 600;
}



@media (max-width: 700px) {
	.progressbar-step{
		display:none;
	}
	.is-current{
		display:block;
	}
	
}




.inputSubTitle{
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.4em;
  font-style: italic;
}


.checkall{
  padding: 5px !important;
  text-align: left!important;
  background-color: #fff!important;
  color: #333!important;
  font-weight: 700!important;
}